.sliding-toggle-container {
    
    position: relative;
  }
  
  .toggle-slider {
    position: relative;
    width: 62px; /* Adjust size */
    height: 32px; /* Adjust height */
    background-color: #2b95ff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
  
  .slider {
    position: absolute;
    width: 44%;
    height: 80%;
    background-color: #fff;
    border-radius: 20px;
    transition: transform 0.3s ease;
  }
  
  .toggle-label {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .toggle-input {
    opacity: 0;
    position: absolute;
  }
  
  .toggle-text {
    font-size: 11px;
    color: #000;
    padding: 12px;
    margin: 0 5px;
  }