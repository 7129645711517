/* styles.css */
.select-wrapper {
    position: relative; /* Needed for positioning the pseudo-element */
    display: inline-block;
  }
  
  .select-wrapper select {
    appearance: none; /* Remove default arrow */
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; /* For Firefox */
    padding: 10px;
    padding-right: 30px; /* Add some space for the custom arrow */
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    width: 80px;
  }
  
  /* Style the custom arrow */
  .select-wrapper::after {
    content: '\25BC'; /* Unicode down arrow character */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent arrow from being clickable */
    color: #007bff;
    font-size: 13px;
  }
  
  /* Optional: Style the select on hover */
  .select-wrapper:hover::after {
    color: #007bff;
  }